import React, { FC } from 'react';
import styled from 'styled-components';
import { brand4, brand5 } from '../../design/colors/brand';
import { ButtonLink } from '../../design/components/button/Button';
import Spacing from '../../design/spacing/Spacing';
import { BodyP } from '../../design/typography/Typography';
import { LinkType } from '../link/types';

export const bannerHeight = '60px';

const SiteBannerContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  height: ${bannerHeight};
  color: ${brand5};
  background-color: ${brand4};
`;

type BannerProps = {
  bannerText?: string | null;
  button1: LinkType;
  button2: LinkType;
};

const CtaContainer = styled.div`
  max-width: fit-content;
  display: flex;
  flex-direction: row;
`;

const StyledButtonLink = styled(ButtonLink)`
  white-space: nowrap;
`;

/**
 * Renders Prismic Site Banner.
 */
const Banner: FC<BannerProps> = ({ bannerText, button1, button2 }) => (
  <SiteBannerContainer data-testid="banner">
    <Spacing right={{ base: '8px', breakpointSmall: '16px' }}>
      <BodyP type="bodyMMedium">{bannerText}</BodyP>
    </Spacing>
    <CtaContainer>
      <StyledButtonLink styleType="primary" link={button1.link} size={{ base: 'small', breakpointSmall: 'medium' }}>
        {button1.text}
      </StyledButtonLink>
      <Spacing left="8px">
        <StyledButtonLink
          styleType="reverseSecondary"
          link={button2.link}
          size={{ base: 'small', breakpointSmall: 'medium' }}
        >
          {button2.text}
        </StyledButtonLink>
      </Spacing>
    </CtaContainer>
  </SiteBannerContainer>
);
export default Banner;
