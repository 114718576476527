import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import BannerData from '../../components/banner/data/Banner.queryData';
import HubspotForm from '../../components/hubspotForm/HubspotForm';
import { submitButtonContainer } from '../../components/hubspotForm/hubspotFormStyles';
import { FullPageModal } from '../../design/modals/FullPageModal';
import { PageHead } from '../../pageContainers/PageHead';
import { objectData } from '../../utils/lodashHelpers';

const CustomizedHubspotForm = styled(HubspotForm)`
  ${submitButtonContainer} {
    display: flex;
    justify-content: center;
  }
`;

type HubspotFormModalProps = { hubspotFormId?: string | null };
const HubspotFormModal: FC<HubspotFormModalProps> = ({ hubspotFormId }) => {
  const [submitted, setSubmitted] = useState(false);

  return hubspotFormId && !submitted ? (
    <FullPageModal>
      <CustomizedHubspotForm
        id={hubspotFormId}
        trackingEvent="Start Product Tour"
        onFormSubmitted={() => setSubmitted(true)}
      />
    </FullPageModal>
  ) : null;
};

const IframePageContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const IFrameContainer = styled.div`
  position: relative;
  flex: 1 0 auto;
  overflow: hidden;
`;

const IFrame = styled.iframe`
  border: none;
`;

const IFramePage: FC<PageProps<Queries.IFrameQuery>> = ({ data }) => {
  const pageData = data?.prismicIframePage?.data;
  const iFrameEmbedLink = pageData?.embed_link?.url || '';
  const modalHubspotFormId = pageData?.modalHubspotFormId;

  return (
    <IframePageContainer>
      <PageHead
        seoProps={{
          title: pageData?.Title || '',
          description: pageData?.Description || '',
          ogImage: objectData(pageData, 'Image.url'),
          exclude_from_search_engines: pageData?.exclude_from_search_engines || false,
          last_publication_date: data?.prismicIframePage?.last_publication_date,
        }}
      />
      <BannerData />
      <IFrameContainer>
        <HubspotFormModal hubspotFormId={modalHubspotFormId} />
        <IFrame data-testid="iframe" src={iFrameEmbedLink} height="100%" width="100%" />
      </IFrameContainer>
    </IframePageContainer>
  );
};

export default withPrismicPreview(IFramePage);

export const query = graphql`
  query IFrame($url: String!) {
    prismicIframePage(url: { eq: $url }) {
      _previewable
      last_publication_date
      data {
        ...IFrameSEO
        modalHubspotFormId
        embed_link {
          url
        }
      }
    }
  }
`;
