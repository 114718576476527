import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Banner from '../Banner';

type LocationType = {
  pathname: string;
};

const findBannerTargetPageMatch = (allBanners: Queries.BannerQuery, location: LocationType) =>
  allBanners?.allPrismicBanner?.nodes.find((banner) =>
    banner.data?.targetPages?.find((target) => target?.targetPage && location.pathname.search(target.targetPage) !== -1)
  );

/**
 * Fetches data for all banners and returns a banner if there is a match in the target page.
 */
const BannerData = () => {
  const allBanners: Queries.BannerQuery = useStaticQuery(graphql`
    query Banner {
      allPrismicBanner {
        nodes {
          data {
            bannerText
            button1Text
            button1Link {
              ...PrismicLink
            }
            button2Text
            button2Link {
              ...PrismicLink
            }
            targetPages {
              targetPage
            }
          }
        }
      }
    }
  `);
  const location = useLocation();
  const banner = findBannerTargetPageMatch(allBanners, location);

  return banner ? (
    <Banner
      bannerText={banner?.data?.bannerText}
      button1={{ text: banner?.data?.button1Text || '', link: banner?.data?.button1Link }}
      button2={{ text: banner?.data?.button2Text || '', link: banner?.data?.button2Link }}
    />
  ) : null;
};

export default BannerData;
