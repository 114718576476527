import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Panel } from '../containers/panel/Panel';

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
`;

const FullPageOverlay = styled(Overlay)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

/**
 * Renders a panel with provided children on a translucent full-page overlay.
 */
export const FullPageModal: FC<PropsWithChildren> = ({ children }) => (
  <FullPageOverlay data-testid="full-page-modal">
    <Panel background="whiteSolid" borderRadius="12px" padding="48px">
      {children}
    </Panel>
  </FullPageOverlay>
);
